import { t } from '../languages';
import service from "./service.js";
import request from "./http.js";

const org = {
    //获取图层表格数据
    getTeamList: (params) => {
        return service.get({
            url: "/api/v1/team/list",
            params
        })
    },
    //重命名团队名称
    rewriteTeamName: (data) => {
        return service.put({
            url: `/api/v1/settings/team`,
            data
        });
    },
    //获取团队树
    getTList: () => {
        return service.get({
            url: "/api/v1/show/team/teamTreeList"
        })
    },
    //添加团队
    addTeam: (data) => {
        return service.post({
            url: `/api/v1/add/team`,
            data
        });
    },
    // 获取组织导航列表
    getNav:(params) => {
        return service.get({
            url: "/api/v1/team/nav",
            params
        })
    },
    //删除团队
    deleteTeam: (data) => {
        return service.delete({
            url: `/api/v1/delete/teams`,
            data
        });
    },
}
export default org