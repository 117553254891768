<template>
  <!-- 添加组织 -->
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('1169')"
    :dialogWidth="'23.75rem'"
    @on-cancel="$emit('update:isVisible', false)"
    @on-add="save"
  >
    <el-form
      ref="elFormRef"
      :model="elForm"
      :rules="formRules"
      label-width="80px"
      label-position="top"
      class="el_form"
      @submit.prevent
    >
      <el-form-item :label="t('718')" prop="teamName">
        <el-input
          class="common-input"
          v-model.trim="elForm.teamName"
          :placeholder="t('716')"
          maxlength="10"
        ></el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script>
import { t } from '../../../languages';
import { defineComponent, reactive, toRefs, ref } from "vue";
import org from "@/network/org";
import { ElMessage } from "element-plus";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    pid: {
      type: String,
      default: "",
    },
  },
  emit: ["update:isVisible", "reloadList", "reloadData"],
  setup(props, { emit }) {
    const state = reactive({
      elForm: {
        teamName: "",
      },
      formRules: {
        teamName: [
          { required: true, message: t("716"), trigger: "blur" },
          {
            max: 10,
            message: t("1170"),
            trigger: "blur",
          },
        ],
      },
    });

    const { pid } = toRefs(props);

    const elFormRef = ref(null);
    /* 保存 */
    function save() {
      elFormRef.value.validate(async (valid) => {
        if (valid) {
          const res = await org.addTeam({
            pid: pid.value,
            teamName: state.elForm.teamName,
          });
          if (res.resultStatus) {
            ElMessage.success(t("713"));
            emit("update:isVisible", false);
            emit("reloadData");
            emit("reloadList");
          }
        }
      });
    }

    return {
      ...toRefs(state),
      elFormRef,
      save,
    };
  },
});
</script>
<style lang="less" scoped>
.el_form {
  /deep/.el-form-item__label {
    color: #fff;
  }
}
</style>
