<template>
  <div class="container">
    <nav class="nav">
      <NavMenu :navlist="navlist" class="nav-left"></NavMenu>
      <div class="nav-right">
        <div class="nav-right-top">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item @click="getOneLevelData"
              >{{ t('661') }}</el-breadcrumb-item
            >
            <el-breadcrumb-item
              v-for="item in fileList"
              :key="item.id"
              @click="breadJump(item)"
              ><span>{{ item.teamName }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <div class="icon_list">
            <div class="icon_btn blue_btn" @click="addTeam" v-checkAuth="'user-team-add'">
              <el-icon class="element_icon"> <CirclePlusFilled /> </el-icon>
              <p>&nbsp;{{ t('209') }}</p>
            </div>
            <div
              class="icon_btn red_btn"
              @click="batchDel"
              style="margin-top: 0.8px"
              v-checkAuth="'user-team-delete'"
            >
              <el-icon class="element_icon"> <Delete /> </el-icon>
              <p>&nbsp;{{ t('210') }}</p>
            </div>
          </div>
        </div>
        <div class="nav-right-bottom">
          <el-cascader
            ref="teamRef"
            class="common-input"
            :placeholder="t('716')"
            :options="teamList"
            :props="state.teamCasDer"
            :show-all-levels="false"
            @change="teamChange"
            filterable
            clearable
            popper-class="teamedit disabled_hide_cacader"
            v-model="showTeam"
          />
        </div>
      </div>
    </nav>
    <!-- 表格部分 -->
    <div class="content">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="tableData"
        v-model:pageNo="state.queryForm.pageNo"
        v-model:pageSize="state.queryForm.pageSize"
        :total="state.queryForm.total"
        @handleNoChange="getData"
        @handleSizeChange="getData"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot="{ prop, row }">
          <span
            class="teamName"
            v-if="prop === 'teamName' && rowId !== row.id"
            @click="editAirName(row)"
          >
            {{ row.teamName }}
          </span>
          <el-input
            ref="inputRef"
            @blur="changeName(row)"
            type="text"
            v-if="prop === 'teamName' && rowId === row.id"
            v-model.trim="row.teamName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            maxlength="10"
          />
        </template>
        <template #buttonHanlder>
          <el-table-column :label="t('610')" align="center" width="150px">
            <template #default="{ row }">
              <el-row>
                <el-col :span="24">
                  <span class="blue_btn" @click="getChildrenData(row)"
                    >{{ t('717') }}</span
                  >
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>
  </div>
  <!-- 添加组织组件 -->
  <AddTeam
    v-if="addOrgShow"
    v-model:isVisible="addOrgShow"
    :pid="pid"
    @reloadData="getData"
    @reloadList="getList"
  />
</template>

<script setup>
import { t } from '../../languages';
import { ref, reactive, nextTick } from "vue";
import NavMenu from "@/components/common/nav-menu.vue";
import AddTeam from "./components/addTeam.vue";
import org from "@/network/org";
import { ElMessage, ElMessageBox } from "element-plus";

const navlist = ref([
  { id: 1, name: t("168"), path: "/personManageIndex" },
  { id: 2, name: t("660"), path: "/roleOrder" },
  { id: 3, name: t("661"), path: "/teamManage" },
]);

const tableColumnArray = [
  { label: t("718"), prop: "teamName" },
  { label: t("719"), prop: "teamGrade" },
  { label: t("411"), prop: "userName" },
  { label: t("412"), prop: "createTime" },
  { label: t("720"), prop: "peopleCount" },
];
const teamRef = ref(null);
const state = reactive({
  queryForm: {
    keyword: "",
    pageNo: 1,
    pageSize: 20,
    total: 0,
  },
  // 修改cascader默认配置
  teamCasDer: {
    value: "id",
    label: "teamName",
    checkStrictly: true,
    children: "children",
    expandTrigger: "hover",
    emitPath: false,
  },
});
const pid = ref(0);
const tableData = ref([]);
const getData = async () => {
  const res = await org.getTeamList({
    pid: pid.value,
    pageNo: state.queryForm.pageNo,
    pageSize: state.queryForm.pageSize,
  });
  if (res.resultStatus) {
    tableData.value = res.resultData.data;
    state.queryForm.total = res.resultData.total;
  }
};
getData();

const addOrgShow = ref(false); //添加/编辑组织弹窗
/* 添加组织名称 */
function addTeam() {
  addOrgShow.value = true;
}

//悬浮修改任务名称
const inputRef = ref(null);
const rowId = ref(null);
const editAirName = (rowData) => {
  if (flag.value) {
    rowId.value = rowData.id;
  }
  nextTick(() => {
    inputRef.value.focus();
  });
};
const flag = ref(true);
const changeName = async (rowData) => {
  if (rowData.teamName.length === 0) {
    ElMessage.error(t("721"));
    flag.value = false;
    return;
  }
  let res = await org.rewriteTeamName({
    id: rowData.id,
    pid:pid.value,
    teamName: rowData.teamName,
  });
  if (res.resultStatus) {
    ElMessage.success({
      duration: 1000,
      message: t("714"),
    });
    rowId.value = "";
    flag.value = true;
  }else{
    flag.value = false;
  }
};

//获取团队名称列表
const teamList = ref([]);
const getList = async () => {
  const res = await org.getTList();
  teamList.value = res.resultData;
};
getList();

//查看团队
const fileList = ref([]);
const getChildrenData = (rowData) => {
  pid.value = rowData.id;
  showTeam.value = pid.value;
  getData();
  fileList.value.push({
    id: rowData.id,
    teamName: rowData.teamName,
  });
};

// 团队管理点击跳转
const getOneLevelData = () => {
  pid.value = 0;
  fileList.value = [];
  showTeam.value = 0;
  getData();
};

//面包屑点击跳转
const showTeam = ref(null);
const breadJump = (ele) => {
  pid.value = ele.id;
  showTeam.value = ele.id;
  let test = fileList.value.findIndex((item) => item.id == ele.id); //找到点击的元素下标
  let arr = fileList.value.slice(test); //取出点击该下标后剩余的数组数据
  fileList.value.splice(test + 1, arr.length - 1); //截取想要的数组
  console.log(test);
  getData();
};

//选择器
const teamChange = async (value) => {
  if(value != null){
    pid.value = value ? value : 0;
    getData();
    fileList.value = [];
    if (pid.value !== 0) {
      const res = await org.getNav({ id: pid.value });
      res.resultData.forEach((item) => {
        fileList.value.push(item);
      });
    }
  }else{
    pid.value = value ? value : 0;
    getData();
    fileList.value = [];
    if (pid.value !== 0) {
      const res = await org.getNav({ id: pid.value });
      res.resultData.forEach((item) => {
        fileList.value.push(item);
      });
    }
    teamRef.value.togglePopperVisible();
  }
  teamRef.value.togglePopperVisible();
};

// 删除团队
const checkedIds = ref([]);
const handleSelectionChange = (id) => {
  let ids = [];
  ids = id.map((item) => item.id);
  checkedIds.value = ids;
};

/* 批量删除 */
function batchDel() {
  if (checkedIds.value.length > 0) {
    ElMessageBox.confirm(
      t("722"),
      t("130"),
      {
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      }
    )
      .then(async () => {
        const res = await org.deleteTeam({
          ids: checkedIds.value,
        });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          getData();
          getList();
        }else{
          getData();
          getList();
        }
      })
      .catch(() => {
        ElMessage(t("549"));
      });
  } else {
    ElMessage.warning(t("659"));
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  .nav {
    display: flex;
    width: 100%;
    height: 6.875rem;
    .nav-left {
      width: 22.5rem;
      height: 100%;
    }
    .nav-right {
      width: calc(100% - 22.5rem);
      height: 100%;
      background: rgba(0, 28, 49, 0.8);
      .nav-right-top {
        display: flex;
        justify-content: space-between;
        padding-top: 0.8125rem;
        padding-left: 2.1875rem;
        padding-right: 0.625rem;
        width: 100%;
        height: 40%;
        .icon_list {
          display: flex;
          align-items: flex-start;
          .icon_btn {
            height: 1.5rem;
          }
        }
        .element_icon {
          font-size: 1.125rem;
        }
      }
      .nav-right-bottom {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60%;
        padding-left: 2.1875rem;
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 6.875rem);
    background: rgba(0, 28, 49, 0.8);
    .teamName {
      cursor: url("../../asset/img/edit-pen.png"), auto;
    }
    .teamName:hover {
      opacity: 0.7;
    }
  }
}
//重写面包屑样式
.el-breadcrumb {
  font-size: 1.25rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  /deep/.el-breadcrumb__inner,
  /deep/.el-breadcrumb__separator {
    color: #36e5ff;
  }
  /deep/.el-breadcrumb__inner:hover{
    color: #36e5ff;
    cursor: pointer;
    opacity: 0.7;
  }
}
</style>